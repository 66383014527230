/*
 * 업무구분 : 청약 바이오인증
 * 화면 명 : MSPPS730M
 * 화면 설명: 지문검증 대상 조회 화면
 */
<template>
  <MSPPS731D v-if="isMSSPS731D" :verifyTargetInfo="verifyTargetInfo" :isFlashEnv="isFlashEnv" @close="isMSSPS731D=false" />
  <ur-page-container v-else class="msp" title="지문검증 대상조회" :show-title="true" type="subpage" :topButton="true"
      @on-header-left-click="fn_HeaderBackBtnHandler"> 

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">
      <div class="bd-b-Ty1 bd-T-Ty1 pal1624 bgcolor-1 w100">
        <span class="fs14rem crTy-bk7">계피상이 계약 중 피보험자의 동의를 지문으로 촬영한 경우, 영수증 번호 조회 후 당시 촬영된 피보험자의 지문과 일치하는지 촬영을 통해 검증할 수 있습니다.</span>
      </div>
      <ur-box-container alignV="start" componentid="" direction="column" class="mt30 ">
        <span class="con-area dsD fwm fs18rem">영수증번호</span>
        <div class="ns-certify-sed mt10"> <!-- <div class="ns-certify-sed error"> 에러시 error 추가 -->
          <div class="ns-certify-sed-code row-text">
            <mo-decimal-field class="full"  mask="####################" type="number" underline placeholder="영수증번호 20자리 입력" :rules="validateRuleId" clearable v-model="searchKeyword" @input="fn_IdNumKeyUp"/>
          </div>
        </div>
      </ur-box-container>
      <div class="con-area mt10">
        <ul class="terms-list-area crTy-bk7 fs14rem">
          <li>영수증ID(번호)는 U포털 內 고객정보조회에서 지점프로가 조회 가능합니다.</li>
        </ul>
      </div>

        
      
    </ur-box-container>
    <!-- Content 영역 end -->
    <template>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative mb60">
        <div class="relative-div">
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" :disabled="!idKeyUpNext" @click="fn_SearchClicked">조회</mo-button>
        </div>
      </ur-box-container>
    </template>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import MSPPS731D from '@/ui/ps/MSPPS731D' 
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import Msg from '@/systems/webkit/msg/msg'


export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS730M', 
  screenId: 'MSPPS730M', 
  components: {
    'MSPPS731D': MSPPS731D
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{},//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      isFlashEnv : false,
      isTabletEvn  : false,       // 테블릿 환경 여부   
      idKeyUpNext : false,
      validateRuleId : [
          v => !!v || '영수증번호 20자리를 입력해주세요.'
         ,v => v.length > 19 || '영수증번호 20자리를 입력해주세요.'
         //,v => this.chkCelnoResult || this.celnoErrorMsg
        ],
      prevScrId: undefined,
      serverChoice: '', // 서버 상태(개발 development, 검증 stage, 운영 production)
      searchKeyword: '',
      title: '지문 검증',
      isMSSPS731D: false,
      verifyTargetInfo: undefined,
      confirmEmptyField: false,
      confirmEmptyFieldTitle: '',
      confirmEmptyFieldMsg: '',
      confirmEmptyFieldCallback: () => {}
    }
  },//data

 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  반드시  next(fals) OR next()  호출 
  //beforeRouteEnter(to, from, next) {},
  
  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  async created () {
    // try {
    //   this.prevScrId = this.$route.params.from
    // } catch (e) {
    //   this.prevScrId = 'TSSBC200M'
    // }
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

    //전자서명 가능 환경    
    this.isTabletEvn = await PSCommUtil.fn_IsElstEnv()
    console.log('>>>>isTabletEvn this.isTabletEvn='+this.isTabletEvn)

    //지문촬영 가능 환경
    await this.fn_PocBioAuthEnv()

    this.fn_init()
    
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
  
  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler() {
      if(PSCommUtil.fn_IsActiveModal()) return
      this.fn_HeaderBackBtnHandler()
    }, //fn_DevicBackBtnHandler

    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : Header Back 타이틀 클릭 핸들러
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
      this.$router.push({ name: 'MSPBC002M' })
      // this.$router.push({name: this.prevScrId})
    },// fn_HeaderBackBtnHandler
    /******************************************************************************
    * Function명  : fn_PocBioAuthEnv
    * 설명        : 지문촬영 가능 환경
    ******************************************************************************/
    async fn_PocBioAuthEnv() {
      
      //지문촬영 가능 환경 여부 조회 결과 : true / false 리턴
      this.isFlashEnv = await PSCommUtil.fn_IsBioAthntEnv() 
      console.log('★★★★★★★★지문촬영 가능 환경 여부 fn_PocBioAuthEnv() = '+this.isFlashEnv)
      if(!this.isFlashEnv){
        this.getStore('confirm').dispatch('ADD', '지문촬영은 플래시가 있는 기기에서 진행가능하며 플래시 기능이 없는 경우 정보확인만 가능합니다.')
      }
    },
    /******************************************************************************
    * Function명 : fn_init
    * 설명       : 초기화
    ******************************************************************************/
    fn_init () {
      this.serverChoice = process.env.NODE_ENV // 현재 접속 앱 설정(개발 development,검증 stage,운영)
    },
    /************************************************************************************************
     * Function명  : fn_SendPush
     * 설명        : 푸시전송
     ************************************************************************************************/
    fn_SendPush () {
      let lv_Vm = this
      const trnstId = 'txTSSPS40U3'
      const auth = 'S'
      let pParams = {bioSvcTp: '1', bioSvcId: this.searchKeyword } // 영수증ID

      this.post(lv_Vm, pParams, trnstId, auth, {}, true).then(function (response) {
        if (response.body) {
          if ( response.body.isPushYN === 'Y' ) { // 푸시정상적으로 전송
            lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('ps')['EPSC042'] )
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '푸시전송실패 : 연결된 휴대폰이 없습니다.' )
          }
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    /******************************************************************************
    * Function명 : fn_ShowConfirm
    * 설명       : 알림 다이얼로그 띄우기
    ******************************************************************************/
    fn_ShowConfirm (title, desc, positiveEvent) {
      // 예)
      // fieldName : 영수증ID를
      // fieldUsageType : 입력
      // positiveEvent : 확인 버튼이 눌리면 실행될 함수

      // 다이얼로그 메시지 예 -> '조회할 {0}을 {1}해 주세요.'.replace('{0}', '피보험자 이름').replace('{1}', '입력')
      // this.confirmEmptyFieldTitle = title
      // this.confirmEmptyFieldMsg = desc
      // this.confirmEmptyFieldCallback = positiveEvent
      // this.confirmEmptyField = true
      this.fn_EmptyConfirmY(title, desc, positiveEvent)
    },

    /******************************************************************************
    * Function명 : fn_EmptyConfirmY
    * 설명       : Confirm 팝업 호출
    ******************************************************************************/
    fn_EmptyConfirmY (title, desc, positiveEvent) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title: title,
          content: desc,
          single: true
        },
        listeners: {
          onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_AlertPop);
           positiveEvent
          }
        }
      })
    },

    /******************************************************************************
    * Function명 : fn_SearchClicked
    * 설명       : 조회 버튼 눌림
    ******************************************************************************/
    fn_SearchClicked () {
      if (!this.searchKeyword || this.searchKeyword.trim() === '') {
        let title = this.$t('ps')['EPSC051']
        let desc = this.$t('ps')['EPSC052']
        this.fn_ShowConfirm(title, desc, () => {})
        return
      }

      this.fn_SearchVerifyTarget()
    },
    fn_IdNumKeyUp() {
      if (this.searchKeyword.length === 20) {
        this.idKeyUpNext = true
      }else{
        this.idKeyUpNext = false
      }
    },
    /******************************************************************************
    * Function명 : fn_SearchVerifyTarget
    * 설명       : 검증 대상 조회
    ******************************************************************************/
    fn_SearchVerifyTarget () {
      let self = this
      let searchParams = {
        vuchId: this.searchKeyword
      }
      this.post(this, searchParams, 'txTSSPS42S1', 'S', {}, false)
        .then(function (response) {
          console.log(response)
          if (response && response.body) {
            let isProcOk = response.body.isProcOk
            if (isProcOk === 'Y') { // 일치하는 영수증 ID가 있을 때
            
              self.fn_StartVerification(response.body)
            
            } else {
              let title = self.$t('ps')['EPSC051']
              let desc = self.$t('ps')['EPSC053']
              self.fn_ShowConfirm(title, desc, () => {})
              // self.fn_StartVerification(response.body)
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_StartVerification
    * 설명       : 지문 검증 화면 띄우기
    ******************************************************************************/
    fn_StartVerification (verifyTargetInfo) {
      this.verifyTargetInfo = verifyTargetInfo
      this.isMSSPS731D = true
    },



    _fn_End(){return}//코딩 종료 함수 메서드 함수 , 없음 주의

 },// methods

}//export default


</script>